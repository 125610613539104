import React, {Component} from 'react';
import Layout from '../../../templates/layout';
import CompanyCard from "../../../components/companyCards/companyCard";
import BasePageContent from '../../../components/utils/BasePageContent';

class FirmaPage extends Component {
  render() {
    return (
      <Layout>
        <BasePageContent>
          <CompanyCard code={this.props.code} location={this.props.location} />
        </BasePageContent>
      </Layout>
    );
  }
}

export const Head = () => <title>Lista podmiotów | Zapłatomat</title>;

export default FirmaPage;
