import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from '../../actions'
import BaseLoader from "../utils/BaseLoader";


const CompanyCard = ({companyData, actions, code}) => {

  useEffect(() => {
    actions.findCompanyCardByCode(code)
  }, [code]);

  if (companyData?.data.code !== code) return <BaseLoader loading={true}/>

  return (
    <>

    </>

  );
}

const mapStateToProps = ({companyCard}) => ({companyData: companyCard});
const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(actions, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCard);

